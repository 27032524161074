// SF PRO DISPPLAY
@font-face {
  font-family: 'SF Pro Display Regular';
  src: url(../../public/font/SFProDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  src: url(../../public/font/SFProDisplay-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display SemiBold';
  src: url(../../public/font/SFProDisplay-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url(../../public/font/SFProDisplay-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Heavy';
  src: url(../../public/font/SF-Pro-Display-Heavy.otf) format('truetype');
}

// TYPOLD EXTENDED
@font-face {
  font-family: 'Typold Extended Medium';
  src: url(../../public/font/Typold-Extended-Medium.otf) format('truetype');
}
