// Text
.header {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.4px;
}

.subheader {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.regular-text {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.4px;
}

// Hide scrollbar
.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
}

// Gradient
.gradient-0 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.03%, #000 68.58%);
}

.gradient-1 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.03%, #000 99.97%);
}

.gradient-2 {
  background: linear-gradient(211deg, rgba(249, 248, 245, 0) -0.07%, #f9f8f5 59.07%);
}

.gradient-3 {
  background: linear-gradient(180deg, #fff 20.95%, #f9f8f5 66.27%);
}

.gradient-4 {
  background: linear-gradient(91deg, #beaf87 -0.05%, #a19276 39.7%);
}

.gradient-5 {
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}

.gradient-6 {
  background: linear-gradient(90deg, rgba(190, 175, 135, 0) 20%, rgba(190, 175, 135, 0.8) 100%);
}

.gradient-7 {
  background: linear-gradient(180deg, #f9f4e7 0%, #fafafa 50%);
}
.gradient-8 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.box-shadow-1 {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.02);
}

.ellipses-1-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main-wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-wrapper {
  width: 100%;
  max-width: 1160px;
}

@media (max-width: 1439px) {
  .content-wrapper {
    max-width: 904px;
  }
}

@media (max-width: 1023px) {
  .content-wrapper {
    max-width: 648px;
  }
}

@media (max-width: 767px) {
  .content-wrapper {
    max-width: 400px;
  }
}

@media (max-width: 479px) {
  .content-wrapper {
    max-width: 327px;
  }
}

@media (max-width: 374px) {
  .header {
    font-size: 34px;
    line-height: 41px;
    letter-spacing: -0.4px;
  }

  .content-wrapper {
    max-width: unset;
    padding: 0 24px;
  }
}

@media (min-width: 840px) {
  .header {
    font-size: 44px;
    line-height: 51px;
    letter-spacing: -0.4px;
  }
}

@media (min-width: 1068px) {
  .header {
    font-size: 52px;
    line-height: 61px;
    letter-spacing: -0.4px;
  }

  .regular-text {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
}
