/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-family: 'SF Pro Display Regular';
  color: #252526;
}

p{
  letter-spacing: -0.4px;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove Firefox's outline style */
button,
input,
textarea,
select {
  outline: none;
  &:focus-visible, 
  &:focus {
    outline: none;
  }
}

/* Reset styles for form elements */
button,
input,
optgroup,
select,
textarea {
  margin: 0; /* Remove the margin in Firefox and Safari */
  font-size: 100%; /* Normalize font size in all browsers */
  line-height: 1.15; /* Normalize line height in all browsers */
  padding: 0; /* Remove padding in Firefox */
  border: 0;
  appearance: none;
}

/* Reset styles for buttons */
button {
  background-color: transparent; /* Reset background color */
  border: 0; /* Reset border */
  cursor: pointer;
}

/* Reset styles for links */
a {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit text color */
  cursor: pointer;
}
